export const STEPS = {
    LOGIN: 'login'
}

export const DIGITAL_ACCOUNT_NETWORK_ERRORS = {
    NOT_FOUND_CPF: {
        code: 'not-found-cpf',
        message: 'CPF não identificado. Contate nossos consultores pelo canal de atendimento'
    },
    INVALID_CPF: { code: 'invalid-cpf', message: 'CPF inválido' },
    USER_NOT_FOUND: {
        code: 'user-not-found',
        message: 'CPF não identificado. Cadastre-se para acessar'
    },
    PHONE_ALREADY_VALIDATED: { code: 'already-validated', message: 'Telefone já validado' },
    INVALID_TOKEN: { code: 'invalid-token', message: 'Pin inválido' },
    DOCUMENTS_MISSING: { code: 'documents-missing', message: 'Documentação incompleta' },
    ATTEMPTS_EXHAUSTED: { code: 'attempts-exhausted', message: 'Tentativas esgotadas' },
    ACCOUNT_INACTIVE: { code: 'login-account-closed', message: 'Conta encerrada' },
    USER_INACTIVE: { code: 'login-account-closed', message: 'Conta encerrada' },
    PASSWORD_LENGTH: {
        code: 'login-password-length',
        message: 'Senha deve conter entre 6 e 20 dígitos'
    }
}

export const DIGITAL_ACCOUNT_NETWORK_LOGIN_ERRORS = {
    USER_BLOCKED_BY_FAILED_ATTEMPTS: {
        code: 'USER_BLOCKED_BY_FAILED_ATTEMPTS',
        message: 'Usuário bloqueado por tentativas inválidas',
        img: '/images/emoji_sm_erro.png',
        description:
            'Seu acesso foi bloqueado por tentativas inválidas. Entre em contato com a equipe.'
    },
    EMAIL_NOT_FOUND: {
        code: 'EMAIL_NOT_FOUND',
        message: 'Usuário não possui e-mail não cadastrado',
        img: '/images/emoji_md_attention.png',
        description: 'Esse usuário não possui e-mail cadastrado. Entre em contato com a equipe.'
    },
    USER_NOT_BLOCKED: {
        code: 'USER_NOT_BLOCKED',
        message: 'O usuário não está apto para desbloqueio',
        img: '/images/emoji_md_attention.png',
        description: 'O usuário não está apto para desbloqueio. Entre em contato com a equipe.'
    },
    UNKNOWN_ERROR: { code: 'UNKNOWN_ERROR' },
    INVALID_CPF: { code: 'invalid-cpf', message: 'CPF inválido' },
    USER_NOT_FOUND: {
        code: 'USER_NOT_FOUND',
        message: 'CPF não cadastrado',
        img: '/images/emoji_md_attention.png',
        description: 'Ops! Parece que o CPF inserido não está cadastrado.'
    },
    USER_NOT_AVAILABLE: {
        code: 'USER_NOT_AVAILABLE',
        message: 'Usuário não disponível!',
        img: '/images/emoji_md_attention.png',
        description: 'Ops! Seu acesso ainda não foi liberado.'
    },
    ACCOUNT_NOT_AVAILABLE: {
        code: 'ACCOUNT_NOT_AVAILABLE',
        message: 'Conta não disponível',
        img: '/images/emoji_sm_erro.png',
        description: 'Ops! A conta que você tentou acessar não está disponível.'
    },
    USER_BLOCKED: {
        code: 'USER_BLOCKED',
        message: 'Usuário bloqueado!',
        img: '/images/emoji_sm_erro.png',
        description: (
            <>
                Ops! Parece que você inseriu o <span>código incorreto</span> várias vezes e, por
                razões de segurança, <span>seu acesso foi bloqueado temporariamente.</span>
            </>
        )
    },
    ACCOUNT_BLOCKED: {
        code: 'ACCOUNT_BLOCKED',
        message: 'Conta bloqueada'
    },
    INVALID_PASSWORD: {
        code: 'INVALID_PASSWORD',
        message: 'Senha inválida',
        img: '/images/emoji_sm_erro.png',
        description: 'Ops! Parece que você inseriu a senha incorreta. Tente novamente.'
    },
    USER_HAS_NO_ACTIVE_ACCOUNTS: {
        code: 'USER_HAS_NO_ACTIVE_ACCOUNTS',
        message: 'Nenhuma conta ativa',
        img: '/images/emoji_sm_erro.png',
        description: 'Ops! Seu acesso ainda não foi liberado.'
    },
    ACCOUNT_INACTIVE: {
        code: 'ACCOUNT_INACTIVE',
        message: 'Conta encerrada'
    },
    USER_INACTIVE: {
        code: 'USER_INACTIVE',
        message: 'Conta encerrada'
    },
    INVALID_TOKEN: {
        code: 'INVALID_TOKEN',
        message: 'Token inválido',
        img: '/images/emoji_sm_erro.png',
        description: 'Ops! Sessão expirada. Faça login novamente.'
    },
    BLOCKED_BY_PIN_VALIDATION: {
        code: 'BLOCKED_BY_PIN_VALIDATION',
        message: 'Usuário bloqueado por tentativas inválidas',
        img: '/images/emoji_sm_erro.png',
        description:
            'Seu acesso foi bloqueado por tentativas inválidas. Entre em contato com a equipe.'
    }
}

export const LOGIN_SUBSTEPS = {
    LOGIN_RESET_PASSWORD: 'login-reset-password',
    LOGIN_LOADING: 'login-loading',
    LOGIN_ERROR: 'login-error',
    LOGIN_ACCESS_BLOCKED: 'login-attempts-error',
    LOGIN_PASSWORD: 'login-password',
    LOGIN_SELECT_ACCOUNT: 'login-select-account',
    LOGIN_AUTO_REDIRECT: 'login-auto-redirect'
}

export const LOGIN_ERRORS = {
    ATTEMPTS_EXHAUSTED: 'ATTEMPTS_EXHAUSTED',
    INVALID_PIN: 'INVALID_PIN',
    ERROR: 'ERROR'
}

import apiClient from '@/helpers/service'
import { handleResponseError } from '@/helpers'
import { DIGITAL_ACCOUNT_NETWORK_LOGIN_ERRORS } from './constants'
import baasClient from '@/helpers/baasService'

export const getLoginType = async (cpf, audit) => {
    try {
        const response = await apiClient.post(
            `/supply-bff/supplier/digital-account/${cpf}/sign-in-type`,
            {
                audit
            }
        )
        if (!response?.data?.success) {
            const errorCode = response.data.error?.code

            if (
                errorCode &&
                Object.values(DIGITAL_ACCOUNT_NETWORK_LOGIN_ERRORS).some(
                    (error) => error.code === errorCode
                )
            ) {
                return {
                    success: false,
                    code: errorCode,
                    msg: DIGITAL_ACCOUNT_NETWORK_LOGIN_ERRORS[errorCode].message
                }
            } else if (errorCode) {
                return {
                    success: false,
                    msg: DIGITAL_ACCOUNT_NETWORK_LOGIN_ERRORS.UNKNOWN_ERROR.message
                }
            } else {
                return {
                    success: false,
                    msg: DIGITAL_ACCOUNT_NETWORK_LOGIN_ERRORS.INVALID_CPF.message
                }
            }
        }
        return {
            success: response.data?.success,
            data: response.data.data?.type
        }
    } catch (err) {
        return handleResponseError(err)
    }
}

export const getCpfValidate = async (cpf) => {
    try {
        const response = await apiClient.get(`/supply-bff/supplier/digital-account/${cpf}/token`)
        if (!response?.data?.success) {
            const errorCode = response.data.error?.code

            if (
                errorCode &&
                Object.values(DIGITAL_ACCOUNT_NETWORK_LOGIN_ERRORS).some(
                    (error) => error.code === errorCode
                )
            ) {
                return {
                    success: false,
                    code: errorCode,
                    msg: DIGITAL_ACCOUNT_NETWORK_LOGIN_ERRORS[errorCode].message
                }
            } else if (errorCode) {
                return {
                    success: false,
                    msg: DIGITAL_ACCOUNT_NETWORK_LOGIN_ERRORS.UNKNOWN_ERROR.message
                }
            } else {
                return {
                    success: false,
                    msg: DIGITAL_ACCOUNT_NETWORK_LOGIN_ERRORS.INVALID_CPF.message
                }
            }
        }

        return {
            success: response.data?.success,
            data: response.data.data
        }
    } catch (err) {
        return handleResponseError(err)
    }
}

export const requestDigitalAccountLogin = async (preLoginResponse, selectedAccount, audit) => {
    try {
        const response = await apiClient.post(
            `/baas/auth/login`,
            {
                account_id: selectedAccount,
                audit
            },
            {
                headers: {
                    Authorization: `Bearer ${preLoginResponse.token}`
                }
            }
        )

        const bffSuccess = response.data?.success
        const serverSuccess = response.data?.data?.success

        if (!bffSuccess || !serverSuccess) {
            const errorCode = response.data.error?.code

            if (!bffSuccess && response.data?.status === 401) {
                return {
                    success: false,
                    code: DIGITAL_ACCOUNT_NETWORK_LOGIN_ERRORS.INVALID_TOKEN.code
                }
            } else if (
                errorCode &&
                Object.values(DIGITAL_ACCOUNT_NETWORK_LOGIN_ERRORS).some(
                    (error) => error.code === errorCode
                )
            ) {
                return {
                    success: false,
                    code: errorCode,
                    msg: DIGITAL_ACCOUNT_NETWORK_LOGIN_ERRORS[errorCode].message
                }
            } else if (errorCode) {
                return {
                    success: false,
                    msg: DIGITAL_ACCOUNT_NETWORK_LOGIN_ERRORS.UNKNOWN_ERROR.message
                }
            } else {
                return handleResponseError(response)
            }
        }

        const {
            access_token,
            access_token_expires_in,
            first_login,
            should_set_transaction_password
        } = response.data?.data

        return {
            success: true,
            first_login,
            access_token,
            access_token_expires_in,
            audit,
            should_set_transaction_password
        }
    } catch (err) {
        return handleResponseError(err)
    }
}

export const requestDigitalAccountPreLogin = async (document, password) => {
    try {
        const response = await apiClient.post(`/baas/auth/pre-login`, {
            document,
            password
        })

        const bffSuccess = response.data?.success
        const serverSuccess = response.data?.data?.success

        if (!bffSuccess || !serverSuccess) {
            const errorCode = response.data.error?.code

            if (
                errorCode &&
                Object.values(DIGITAL_ACCOUNT_NETWORK_LOGIN_ERRORS).some(
                    (error) => error.code === errorCode
                )
            ) {
                return {
                    success: false,
                    code: errorCode,
                    msg: DIGITAL_ACCOUNT_NETWORK_LOGIN_ERRORS[errorCode].message
                }
            } else if (errorCode) {
                return {
                    success: false,
                    msg: DIGITAL_ACCOUNT_NETWORK_LOGIN_ERRORS.UNKNOWN_ERROR.message
                }
            } else {
                if (response.data?.data?.attempts_left === 0) {
                    return {
                        success: false,
                        code: DIGITAL_ACCOUNT_NETWORK_LOGIN_ERRORS.USER_BLOCKED.code
                    }
                } else {
                    return {
                        success: false,
                        code: DIGITAL_ACCOUNT_NETWORK_LOGIN_ERRORS.INVALID_PASSWORD.code,
                        attemptsLeft: response.data?.data?.attempts_left
                    }
                }
            }
        }

        return {
            success: true,
            token: response.data?.data?.token,
            availableAccounts: response.data?.data?.available_accounts,
            name: response.data?.data?.name
        }
    } catch (err) {
        return handleResponseError(err)
    }
}

export const unlockUserAccountRequest = async (cpf) => {
    try {
        const response = await baasClient().post(
            `auth/password/user/${cpf}/send-authorization-email/user-blocked`
        )

        if (!response.data.success) {
            return {
                success: false,
                message: response.data.message
            }
        }
        return {
            success: true,
            email: response.data.data.email
        }
    } catch (err) {
        return handleResponseError(err)
    }
}

export const requestForgotPassword = async (cpf) => {
    try {
        const response = await baasClient().post(
            `auth/password/user/${cpf}/send-authorization-email/forgot-password`
        )
        if (!response?.data?.success) {
            const errorCode = response.data.error?.code

            if (
                errorCode &&
                Object.values(DIGITAL_ACCOUNT_NETWORK_LOGIN_ERRORS).some(
                    (error) => error.code === errorCode
                )
            ) {
                return {
                    success: false,
                    code: errorCode,
                    msg: DIGITAL_ACCOUNT_NETWORK_LOGIN_ERRORS[errorCode].message
                }
            } else if (errorCode) {
                return {
                    success: false,
                    msg: DIGITAL_ACCOUNT_NETWORK_LOGIN_ERRORS.UNKNOWN_ERROR.message
                }
            } else {
                return {
                    success: false,
                    msg: DIGITAL_ACCOUNT_NETWORK_LOGIN_ERRORS.INVALID_CPF.message
                }
            }
        }

        return {
            success: response.data?.success,
            email: response.data.data.email
        }
    } catch (err) {
        return handleResponseError(err)
    }
}
